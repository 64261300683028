.menu-item {
	width: 60px;
	height: 21px;
	border: 1px solid #BBB9B9;
	box-sizing: border-box;
	border-radius: 10px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding-top: 4px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 800;
	font-size: 10px;
	line-height: 12px;
}

.menu-item:hover {
	color: white;
	background-color: #1CAA53;
}

.percent-types-inline {
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin-top: 10px;
}