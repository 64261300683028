.content-error-block {
    color: #d46f61;
    font-size: 12pt;
    text-align: center
}

.content-header{
    margin-bottom: 30px;
    text-align: left;
    font-size: 14pt;
}

.ant-table-small > .ant-table-content > .ant-table-body{
    margin: 0;
}

.btn-create-entity {
    float: right;
    border-color: #52c41a;
    color: #52c41a;
}

.btn-create-entity-block {
    margin: 0 0 10px 0;
}

.ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected{
    color: #1CAA53;
}