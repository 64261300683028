.column-title{
    margin: 0 0 10px 0;
}

.ant-menu-submenu-title:hover{
    color: rgba(0, 0, 0, 0.65);
}

.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
    background: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
}