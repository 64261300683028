.b-youtube-app-link {
  margin: 0 auto 7px auto;
  align-items: center;
  max-width: 1108px;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.cabinet-container .b-youtube-app-link
{
  border-radius: 11px;
}

.b-youtube-app-link ul {
  margin: 0 0 0 0;
  padding: 0;
  list-style: none;
}

.b-youtube-app-link ul li:first-child {
  margin-left: 5px;
}
.b-youtube-app-link ul li {
  float: left;
  margin-left: 30px;
  font-size: 20px;
}

.support-link {
  font-size: 20px;
  margin-right: 30px;
  float: right;
}

.b-youtube-list {
  margin: 10px 0 0 40px;
  padding-bottom: 20px;
}

.youtube-app-link__title {
  padding: 35px 30px 0 30px;
}

.youtube-app-link__title p {
  color: #1e1e1e;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
}

p.youtube-app-link__text {
  font-weight: 600;
  font-size: 20px;
  margin: 20px 0 0 0;
}

.youtube-app-link__text span {
  color: #1caa53;
}

.youtube-app-link__image {
  height: 100%;
}

.youtube-app-link__image img {
  max-height: 190px;
}

.youtube-app-link__link {
  padding: 0 30px 0 20px;
  width: 20%;
}

.youtube-app-link__link-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #1e1e1e;
  margin: 0 0 20px 0;
}

@media screen and (max-width: 767px) {
  .b-youtube-app-link {
    flex-direction: column;
  }
  .youtube-app-link__title,
  .youtube-app-link__link {
    width: 100%;
  }

  .support-link {
    margin-top: 20px;
  }

  .b-youtube-app-link ul li, .b-youtube-app-link ul li:first-child {
    margin: 0;
  }

  .youtube-app-link__link {
    display: flex;
    align-items: center;
    margin: 20px 0 20px 0;
  }

  .youtube-app-link__link-text {
    margin: 0 20px 0 0;
  }
}
