.modal-attach-driver__steps-content {
	margin-top: 16px;
}

.modal-attach-driver__steps-content .typical-icon-field.icon-field-cash, .modal-attach-driver__steps-content .typical-icon-field.icon-field-calendar {
	font-size: 16px;
}

.modal-attach-driver__steps-footer {
	margin-top: 20px;
	padding: 15px 0 0 0;
	text-align: left;
	border-top: 1px solid #e8e8e8;
	border-radius: 0 0 4px 4px;
}

.steps-checking-verified-info__header {
	font-size: 25px;
	color: black;
	text-align: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 10px;
	margin-bottom: 25px;
}

.steps-checking-verified-info__error-response {
	color: #d46f61;
	font-size: 14pt;
	text-align: center;
	padding: 10px;
}

.dots-custom {
	width: 90%;
	margin: 0 auto;
}

@media screen and (max-width: 800px) {
	.dots-custom {
		width: 100%;
		margin: 0 auto;
	}
}

.dots-custom .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
	background: #1caa53;
}

.dots-custom .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
	background: #1caa53;
}

.dots-custom .ant-steps-item-finish>.ant-steps-item-tail::after {
	background: #1caa53;
}

.label {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
}

.data {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
	text-align: right;
}

.modal-attach-driver__steps-content .ant-input {
	height: 40px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: white;
	border-color: #1caa53;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
	border-color: #1CAA53 !important;
}

.ant-checkbox-inner::after {
	width: 7px;
	height: 12px;
}

.ant-checkbox-inner {
	width: 24px !important;
	height: 24px !important;
	border-radius: 4px !important;
}

.ant-btn-disabled, .ant-btn.disabled, .ant-btn[disabled], .ant-btn-disabled:hover, .ant-btn.disabled:hover, .ant-btn[disabled]:hover, .ant-btn-disabled:focus, .ant-btn.disabled:focus, .ant-btn[disabled]:focus, .ant-btn-disabled:active, .ant-btn.disabled:active, .ant-btn[disabled]:active, .ant-btn-disabled.active, .ant-btn.disabled.active, .ant-btn[disabled].active {
	color: white !important;
	background-color: #b3b3b3 !important;
}

/* .ant-row {
  margin-bottom: 15px;
} */

.site-navigation-steps-none {
	display: none;
}

.ant-steps-dot .ant-steps-item-content {
	min-width: 120px;
}

.ant-steps-item .ant-steps-item-finish {
	display: none;
}

.ant-steps-small .ant-steps-item-icon {
	display: none;
}

.ant-steps-item::after {
	display: none !important;
}

.ant-steps-item::before {
	background-color: #1CAA53 !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: #1CAA53 !important;
}

.ant-steps-navigation {
	padding-top: 0px !important;
}

.ant-steps {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	background: #1CAA53 !important;
	border-color: #1CAA53 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	background: #1CAA53 !important;
	border-color: #1CAA53 !important;
}

.ant-radio-button-wrapper:hover {
	color: #1CAA53 !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	color: white !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: white !important;
}

.ant-upload>.ant-btn>span {
	font-size: 12px;
}

.left-border-radius .ant-select-selection {
	border-radius: 4px 0 0 4px;
}
