.b-android-app-link {
  /*border: 1px dashed #8A97CC;*/
  border-radius: 6px;
  /*background: #fff;*/
  background: #ffffff;
  border-radius: 0;
  margin: 0 auto 7px auto;
  display: flex;
  align-items: center;
}

.android-app-link__title {
  padding: 35px 30px 25px 30px;
  width: 55%;
}

.android-app-link__title p {
  color: #1e1e1e;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
}

p.android-app-link__text {
  font-weight: 600;
  font-size: 20px;
  margin: 20px 0 0 0;
}

.android-app-link__text span {
  color: #1caa53;
}

.android-app-link__image {
  height: 100%;
}

.android-app-link__image img {
  max-height: 190px;
}

.android-app-link__link {
  padding: 0 30px 0 20px;
  width: 20%;
}

.android-app-link__link-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #1e1e1e;
  margin: 0 0 20px 0;
}

@media screen and (max-width: 767px) {
  .b-android-app-link {
    flex-direction: column;
  }
  .android-app-link__title,
  .android-app-link__link {
    width: 100%;
  }

  .android-app-link__link {
    display: flex;
    align-items: center;
    margin: 20px 0 20px 0;
  }

  .android-app-link__link-text {
    margin: 0 20px 0 0;
  }
}
