.single-btn-upload button, .ant-upload-list-item {
    height: 80px !important;
}

.single-btn-upload button:hover, .single-btn-upload button:visited, .single-btn-upload button:active, .single-btn-upload button:focus {
    background-color: #EEF2F7;
    color: #1caa53;
}

.ant-upload-list-picture {
    margin-bottom: -7px;
}

.ant-btn:hover {
    /* border-color: #1caa53 */
}

.has-error .ant-form-explain {
    padding-top: 10px;
}
